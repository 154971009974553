import { defineStore } from "pinia";
import { t } from "@/plugins/i18n";
import * as listable from "./listableMixin";
import * as permissions from "./permissionMixin";
import { cloneDeep } from "lodash";
import { defaultSearchOptions } from "./listableMixin";
import {
  search as searchFiles,
  deleteFile,
  uploadFile,
  downloadFile,
  editFile,
} from "@/services/filesService";
import { useNotificationsStore } from "@/stores/notifications";
import logger from "@/services/loggerService";
import { FileDownload } from "@/models/fileDownload.interface";

export interface FileDownloadsState
  extends listable.ListableMixinState,
    permissions.PermissionsMixinState {
  items: FileDownload[];
}

export const useFileDownloadsStore = defineStore({
  id: "FileDownloads",
  state: (): FileDownloadsState => ({
    isLoading: false,
    searchOptions: cloneDeep(defaultSearchOptions),
    moduleName: "Files",
    items: [],
    searchError: t("filesFetchError"),
    searchFnc: searchFiles,
  }),
  getters: {
    ...permissions.getters,
  },
  actions: {
    ...listable.actions,
    async delete(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await deleteFile(id);
        notifications.success(t("fileRemoveSuccess"));
        this.items = this.items.filter((file) => {
          if (file.accountFileId != id) {
            return file;
          }
        });
      } catch (err) {
        logger.error(err);
        notifications.error(t("fileRemoveError"));
      } finally {
        this.isLoading = false;
      }
    },
    async upload(file: File, notes = "") {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await uploadFile(
          file,
          "document",
          notes,
          this.searchOptions?.parentAccountId
        );
        this.search();
        notifications.success(t("fileUploadSuccess"));
      } catch (err) {
        logger.error(err);
        notifications.error(t("fileUploadError"));
      } finally {
        this.isLoading = false;
      }
    },
    async edit(file: FileDownload) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await editFile(file.accountFileId, file.fileName, file.fileNote);
        this.items = this.items.map((f) => {
          return f.accountFileId == file.accountFileId ? file : f;
        });
        notifications.success(t("fileEditSuccess"));
      } catch (err) {
        logger.error(err);
        notifications.error(t("fileEditError"));
      } finally {
        this.isLoading = false;
      }
    },
    async download(id: string) {
      this.isLoading = true;
      const notifications = useNotificationsStore();
      try {
        await downloadFile(id);
      } catch (err) {
        logger.error(err);
        notifications.error(t("fileDownloadError"));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
