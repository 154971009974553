import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import Antd from "ant-design-vue";
import { sentryInit } from "@/plugins/sentry";
import router from "@/router";
import { createMetaManager } from "vue-meta";

import "@/styles/main.scss";
import "@/styles/antd.less";
import i18n from "@/plugins/i18n";

import axiosInterceptor from "@/plugins/axiosInterceptor";

axiosInterceptor();

const app = createApp(App);
const pinia = createPinia();
sentryInit(app);
app.use(createMetaManager());
app.use(pinia);
app.use(i18n);
app.use(router);
app.use(Antd);
app.mount("#app");
